import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import router from './router'
import 'element-plus/dist/index.css'


createApp(App)
    .use(router)
    .use(ElementPlus)
    .mount('#app')




router.beforeEach(
    (to, from, next) => {
        /* 路由发生变化修改页面meta */
        if(to.meta.content){
            let head = document.getElementsByTagName('head');
            let meta = document.createElement('meta');
            document.querySelector('meta[name="Keywords"]').setAttribute('content', to.meta.content.Keywords)
            document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
            meta.content = to.meta.content;
            head[0].appendChild(meta)}

        // /* 路由发生变化修改页面title */
        if (to.meta.title) {
            document.title = to.meta.title;
        }
        next()
    });
